<template>
  <div>
    <div class="countArea">
      Unapproved produts:
      <span style="color: #f57373"> {{ unapprovedProductCount }}</span>
    </div>

    <div
      v-if="!products.length"
      class="w-full text-center"
      style="font-size: 1.5rem; font-weight: 600"
    >
      There are no unapproved products
    </div>
    <div v-if="products.length" class="w-full vx-row p-0 m-0">
      <div
        class="vx-col md:w-1/4 product xs:w-1/2 w-full"
        v-for="product in products"
        :key="product.id"
      >
        {{ product.title }}
      </div>
    </div>
    <div id="paginationDiv" class="m-2">
      <vs-pagination
        :total="totalPage"
        v-model="currentPage"
        @change="HandleChangePage(currentPage)"
      />
    </div>
  </div>
</template>

<script>
import Api from "@/api/api";
import API from "@/api/config";

export default {
  data() {
    return {
      unapprovedProductCount: 0,
      products: [],
      currentPage: 1,
      totalPage: 1,
    };
  },
  methods: {
    HandleChangePage() {
      this.GetProducts();
    },

    GetProducts() {
      this.$vs.loading({ text: this.$t("Loading_Please_Wait") });

      var params = { is_approved: false };
      if (this.currentPage != 1) {
        params.page = this.currentPage;
      }

      Api.get(
        API.BASEURL + API.NOKTA_MARKET_PROVIDER_PRODUCT,
        this.HandleGetProducts,
        params
      );
    },
    HandleGetProducts(status, data) {
      this.$vs.loading.close();
      if (status == 200) {
        this.products = data.results;
        this.totalPage = Math.ceil(data.count / 50);
        this.unapprovedProductCount = data.count;
        if (data.count == 0) {
          document.getElementById("paginationDiv").style.display = "none";
        }
      } else {
        this.$vs.notify({
          title: "Error",
          text: "Something went wrong",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
          time: 5000,
        });
      }
    },
  },
};
</script>

<style scoped>
.product {
  font-weight: 600;
  background-color: white;
  padding: 5px;
}
.countArea {
  background-color: white;
  padding: 10px;
  margin-bottom: 10px;
  font-size: 1.2rem;
  font-weight: 600;
  border-radius: 5px;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
}
</style>
